import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from 'Assets/unixi_logo.png';
import styles from './LoginForm.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { useResetPassword } from 'Services/LoginAPI';
import CircularProgress from '@mui/material/CircularProgress';
import global from '../Global.module.css';
import { toast } from 'react-toastify';

// import { useAuthenticate } from 'Services/Authentication';

export default function ForgotLoginForm() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (errorMessage && errorMessage.length > 2) toast.error(errorMessage);
    }, [errorMessage]);

    function DoReset() {
        setLoading(true);
        useResetPassword(username)
            .then((response) => {
                if (response.status) {
                    console.log(response);
                    if (response.status === true) {
                        toast.success('Password reset email sent, please check you inbox.');
                        setTimeout(() => {
                            navigate('/');
                        }, 4000);
                    } else {
                        setErrorMessage(response.info);
                    }
                } else {
                    setErrorMessage(response.info);
                }
            })
            .catch((error) => {
                setErrorMessage(error.toString());
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className={styles.container}>
            <br />
            <img src={logo} className={styles.logo} alt='logo' />
            <br />
            <div className={styles.loginMessageMfa}>
                <div className={styles.errorMessage}> </div>
                {/* <div className={styles.errorMessage}>{errorMessage}</div> */}
            </div>
            <br />

            <form className={styles.form}>
                <TextField
                    onChange={(e) => setUsername(e.target.value)}
                    id='outlined-basic'
                    label={
                        <>
                            {' '}
                            <FontAwesomeIcon icon={faUser} /> Username{' '}
                        </>
                    }
                    variant='outlined'
                    className={[global.input, styles.input].join(' ')}
                />
                <br />
                <Button
                    onClick={DoReset}
                    type='submit'
                    variant='contained'
                    color='error'
                    className={styles.submitButton}
                    disabled={loading}
                >
                    Reset Password&nbsp;
                    {loading ? (
                        <CircularProgress disableShrink size={18} />
                    ) : (
                        <FontAwesomeIcon icon={faRightToBracket} />
                    )}
                </Button>
            </form>
            <div className={styles.linkHolder}>
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
                <Link to='/login' className={styles.link}>
                    Back to Login
                </Link>
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
            </div>
        </div>
    );
}

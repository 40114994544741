import serverName from 'Config/config';
import WrapperAPI from 'Utils/WrapperAPI';

const logoutWithUnixi = async (user_code) => {
    return await WrapperAPI(async () => {
        return await fetch(`${serverName}api/logout_with_unixi/${user_code}/`, {
            method: 'POST',
            credentials: 'include'
        });
    });
};

export { logoutWithUnixi };

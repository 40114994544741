import { createTheme } from '@mui/material/styles';
import styles from 'Components/Global.module.css';

import classNames from 'classnames';

// const muiToolbarBackground = 'linear-gradient(180deg, #454243 0%, rgba(69, 66, 67, 0) 100%)';
const muiToolbarBackground = 'transparent';
const muiTableBackground = 'linear-gradient(180deg, #26272C 0%, #1F2026 100%)';
const muiTableFooterBackground = 'transparent';
const muiTableFooterPagingBackground = 'transparent'; //linear-gradient(180deg, #26272C 0%, #1F2026 100%)';
const muiTableShadow = '0px -1px 0px 0px #EDF2F7 inset';
const muiTableShadowHeavy = '0px -3px 0px 0px #EDF2F7 inset';
const muiTableBorderColor = '3px solid black';
const muiTableOuterBorderColor = '0px solid black';
const muiTableTextColor = '#fffffff0';
const muiTableHeaderColor = '#8492A6';
const muiTableTextSize = '0.85cqw';
const muiTableHeaderSize = '0.80cqw';
const borderRadius = '0px';

const MyTheme = createTheme({
    // overrides: {
    //     MuiOutlinedInput: {
    //       input: {
    //         color: "white",
    //         "&::placeholder": {
    //             color: "white"
    //         },
    //         color: "white", // if you also want to change the color of the input, this is the prop you'd use
    //         },
    //     },
    // },

    // div: {
    //     "& .MuiTableCell-body": {
    //       borderLeft: "1px solid rgba(224, 224, 224, 1)"
    //     }
    // },
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    background: muiTableBackground,
                    boxShadow: muiTableShadow,
                    '&:last-child:not(.MuiTableRow-footer)': {
                        boxShadow: muiTableShadowHeavy
                    }
                },
                footer: {
                    boxShadow: 'none',
                    background: muiTableFooterBackground
                },
                hover: {
                    background: 'rgba(0, 0, 0, 0.2)',
                    ':hover': {
                        // TODO - this doesn't work
                        background: 'rgba(0, 0, 0, 0.2)'
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: muiTableBackground,
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    background: muiToolbarBackground
                }
            }
        },
        MUIDataTableFooter: {
            styleOverrides: {
                root: {
                    borderBottomLeftRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    overflow: 'hidden',
                    background: muiTableFooterBackground,
                    boxShadow: 'none'
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    background: muiTableBackground,
                    boxShadow: muiTableShadow
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    background: muiTableBackground,
                    boxShadow: muiTableShadow
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    borderBottom: muiTableBorderColor
                }
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    background: muiTableBackground,
                    boxShadow: muiTableShadow
                },
                data: {
                    color: muiTableHeaderColor,
                    fontSize: muiTableHeaderSize
                },
                contentWrapper: {
                    justifyContent: 'center'
                },
                toolButton: {
                    marginRight: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }
            }
        },
        MUIDataTableSelectCell: {
            styleOverrides: {
                root: {
                    background: muiTableBackground,
                    boxShadow: muiTableShadow,
                    borderLeft: 'none'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    // boxShadow: muiTableShadow,
                    boxShadow: 'none',
                    borderRadius: borderRadius,
                    borderBottom: 'none',
                    color: muiTableTextColor,
                    border: muiTableOuterBorderColor
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    color: muiTableTextColor,
                    fontSize: muiTableTextSize,
                    paddingInline: '3px',
                    '&.Mui-checked': {
                        color: 'blue'
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: muiTableTextColor
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: muiTableTextColor
                },
                toolbar: {
                    // background: muiToolbarBackground,
                    background: muiTableFooterPagingBackground,
                    borderRadius: borderRadius
                },
                select: {
                    color: muiTableTextColor
                }
            }
        },
        MUIDataTableViewCol: {
            styleOverrides: {
                label: {
                    color: 'white'
                },
                title: {
                    color: muiTableTextColor
                }
            }
        },
        MUIDataTable: {
            styleOverrides: {
                responsiveBase: {
                    overflow: 'visible' // This is done to enable overflow of employee tooltip
                }
            }
        },
        disabled: {
            //??????????????????
            styleOverrides: {
                root: {
                    color: muiTableTextColor
                }
            }
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    color: muiTableTextColor
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: muiTableTextColor,
                    '&.Mui-checked': {
                        color: muiTableTextColor
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: 'none',
                    fontSize: muiTableTextSize,
                    paddingTop: '10px',
                    paddingInline: '2px'
                    // borderLeft: '1px solid white',
                    // borderRight: '1px solid white'
                },
                body: {
                    borderLeft: muiTableBorderColor,
                    borderRight: muiTableBorderColor,
                    '&:last-child': {
                        borderRight: 'none'
                    },
                    '&:first-of-type': {
                        borderLeft: 'none'
                    },
                    textAlign: 'center'
                },
                head: {
                    borderLeft: muiTableBorderColor,
                    // borderRight: '0.5px solid #ffffffdf',
                    '&:last-child': {
                        borderRight: 'none'
                    },
                    '&:first-of-type': {
                        borderLeft: 'none'
                    }
                },
                footer: {},
                paddingCheckbox: {
                    zIndex: 0
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    color: 'white !important'
                }
            }
        },
        MUIDataTableSearch: {
            styleOverrides: {
                searchIcon: {
                    color: 'white'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: 'white'
                }
            }
        },
        // MUIDataTableViewCol: {
        //     styleOverrides: {
        //         title: {
        //            color: 'white'
        //         }
        //     }
        // },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    color: 'white'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    paddingInline: '15px',
                    color: 'white'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    color: 'black'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    color: 'black'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '15px'
                }
            }
        },
        // MuiSvgIcon: {
        //     styleOverrides: {
        //         root: {
        //             color: muiTableTextColor
        //         }
        //     }
        // },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: muiTableTextColor
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    border: '1px solid #ffffff42'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    border: '1px solid #ffffff42'
                }
            }
        },

        // Slider
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    color: 'white'
                }
            }
        },
        // Toggle Switch
        MuiSwitch: {
            styleOverrides: {
                track: {
                    // Background color for the track (the background of the switch)
                    backgroundColor: 'gray' // Use your desired color
                },
                thumb: {
                    // Background color for the thumb (the circular part that moves)
                    // when the switch is unchecked
                    backgroundColor: 'white' // Use your desired color for the thumb
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#f00',
            contrastText: '#fff',
            '&::placeholder': {
                color: 'gray'
            },
            color: 'white' // if you also want to change the color of the input, this is the prop you'd use
        },
        // secondary: {
        //     main: '#f00',
        //     contrastText: '#000',
        //     "&::placeholder": {
        //         color: "gray"
        //     },
        //     color: "white", // if you also want to change the color of the input, this is the prop you'd use
        // },
        primaryOverWhite: {
            main: '#f00',
            contrastText: '#fff',
            '&::placeholder': {
                color: 'gray'
            },
            color: 'white'
        }

        // unixi: {
        //     main: '#0f0',
        //     color: '#000',
        //     contrastText: '#fff',
        // },
        // primary: { // works
        //   main: '#f00',
        //   contrastText: '#fff',
        //   color: '#fff',
        // },
        // secondary: { // works
        //   main: '#69BE28',
        //   contrastText: '#fff',
        // },
        // companyBlue: {
        //     backgroundColor: '#65CFE9',
        //     color: '#fff',
        // },
        // companyRed: {
        //     backgroundColor: '#E44D69',
        //     color: '#000',
        // },
        // accent: {
        //     backgroundColor: purple[500],
        //     color: '#000',
        // },
    }
});

const selectInputStyle = {
    control: () => classNames(styles.filter_select_control),
    multiValue: () => classNames(styles.filter_select_input),
    multiValueLabel: () => classNames(styles.filter_select_input_value),
    input: () => classNames(styles.filter_select_input_value),
    menu: () => classNames(styles.filter_select_menu),
    option: ({ isFocused, isSelected }) =>
        classNames(
            isSelected && styles.filter_select_option_selected,
            isFocused && styles.filter_select_option_focused,
            styles.filter_select_option
        ),
    singleValue: () => classNames(styles.filter_select_input_value),
    valueContainer: () => classNames(styles.filter_select_value_container),
    placeholder: () => classNames(styles.filter_select_placeholder)
};

export { MyTheme, selectInputStyle };

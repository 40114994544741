import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import OtpInput from './OtpInput';
import logo from 'Assets/unixi_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.css'; // Import the styles
import { UseMfaLogin } from 'Services/LoginAPI';
import { useAuthenticate } from 'Services/Authentication';
import { toast } from 'react-toastify';

const MfaLoginForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newOtp, setOtp] = useState(''); // State to store the combined OTP

    const navigate = useNavigate();

    useEffect(() => {
        if (errorMessage && errorMessage.length > 2) toast.error(errorMessage);
    }, [errorMessage]);

    function handleOtpChange(newOtp) {
        setOtp(newOtp);
    }

    function CompleteLogin(response) {
        useAuthenticate(response.user, response.tenant);
        props?.animateGlobe?.animation();
        const LandingPage = response.tenant.landing_page;

        setTimeout(() => CompleteLogin2(LandingPage), 2000, response);
    }

    function CompleteLogin2(LandingPage) {
        const redirectPath = localStorage.getItem('redirectPath') || '/';
        localStorage.removeItem('redirectPath'); // Clear the saved path
        if (redirectPath === '/') {
            window.location.href = (LandingPage ? `/unixi-management-portal/app/${LandingPage}` : '/unixi-management-portal/app/dashboard');
        } else if (redirectPath.startsWith('/unixi-management-portal')) {
            window.location.href = (`${redirectPath}`);
        } else {
            window.location.href = (`/unixi-management-portal${redirectPath}`);
        }
    }

    function handleMfaLogin() {
        setLoading(true);
        UseMfaLogin(newOtp)
            .then((response) => {
                if (response.status) {
                    console.log(response);
                    if (response.status === true) {
                        CompleteLogin(response);
                    } else {
                        setErrorMessage(response.info);
                    }
                } else {
                    setErrorMessage(response.info);
                }
            })
            .catch((error) => {
                setErrorMessage(error.toString());
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className={styles.container}>
            <br />
            <img src={logo} className={styles.logo} alt='logo' />
            <br />
            <div className={styles.loginMessageMfa}>
                Please enter your OTP to login
                <div className={styles.errorMessage}> </div>
                {/* <div className={styles.errorMessage}>{errorMessage}</div> */}
            </div>
            <br />

            <form className={styles.form}>
                <OtpInput onOtpChange={handleOtpChange} />
                <br />
                <br />
                <Button
                    onClick={handleMfaLogin}
                    type='submit'
                    variant='contained'
                    color='error'
                    className={styles.submitButton}
                    disabled={loading}
                >
                    Verify&nbsp;
                    {loading ? (
                        <CircularProgress disableShrink size={18} />
                    ) : (
                        <FontAwesomeIcon icon={faRightToBracket} />
                    )}
                </Button>
            </form>
            <div className={styles.linkHolder}>
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
                <Link to='/login' className={styles.link}>
                    Back to Login
                </Link>
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
            </div>
        </div>
    );
};

export default MfaLoginForm;

import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';
import React from 'react';
import Router from 'Routes/Router';
import * as Sentry from '@sentry/react';
import InternalErrorPage from './Pages/InternalErrorPage';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router';

if (
    process.env.REACT_APP_ENVIRONMENT === 'prod' ||
    process.env.REACT_APP_ENVIRONMENT === 'demo' ||
    process.env.REACT_APP_ENVIRONMENT === 'test'
) {
    Sentry.init({
        dsn: 'https://350cb74546056593c7daa00926bda1e2@o4506767809708032.ingest.sentry.io/4506768193224704', // Replace with your Sentry DSN
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setTag('environment', process.env.REACT_APP_ENVIRONMENT);
}

function App() {
    return (
        <Sentry.ErrorBoundary fallback={<InternalErrorPage />}>
            <GoogleOAuthProvider clientId="838522614716-9rt9ise4cg38emusr6frbdcm46n4m8nn.apps.googleusercontent.com">
                <Router />
            </GoogleOAuthProvider>
        </Sentry.ErrorBoundary>
    );
}

export default App;

import serverName from 'Config/config';
import WrapperAPI from 'Utils/WrapperAPI';

const useLogin = async (
    username,
    password,
    withUnixi = false,
    userCode = null,
    rememberMe = false
) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/login`, {
                method: 'POST',
                body: JSON.stringify({
                    username: username,
                    password: password,
                    with_unixi: withUnixi,
                    user_code: userCode,
                    remember_me: rememberMe
                }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const UseMfaLogin = async (code) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/login_mfa`, {
                method: 'POST',
                body: JSON.stringify({ token: code }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const useResetPassword = async (username) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/reset_password`, {
                method: 'POST',
                body: JSON.stringify({
                    email: username,
                    new: true
                }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const UseSetPassword = async (token, password) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/set_password`, {
                method: 'POST',
                body: JSON.stringify({ token: token, password: password }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const useLoginMicrosoft = async (credential) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/login`, {
                method: 'POST',
                body: JSON.stringify({ type: 'microsoft', credential: credential }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const useLoginGoogle = async (credential) => {
    return await WrapperAPI(
        async () => {
            return await fetch(`${serverName}api/authentication/login`, {
                method: 'POST',
                body: JSON.stringify({ type: 'google', credential: credential }),
                credentials: 'include'
            });
        },
        {
            ignore_401: true
        }
    );
};

const getProfileData = async () => {
    return await WrapperAPI(async () => {
        return await fetch(`${serverName}api/get_profile_data`, {
            method: 'GET',
            credentials: 'include'
        });
    });
};

export {
    useLogin,
    UseMfaLogin,
    useResetPassword,
    UseSetPassword,
    useLoginGoogle,
    useLoginMicrosoft,
    getProfileData
};

import React, { useRef, useState, useEffect } from 'react';

const OtpInput = ({ onOtpChange }) => {
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const [otp, setOtp] = useState(['', '', '', '', '', '']);

    const updateOtp = (index, value) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);

        const combinedOtp = updatedOtp.join('');
        onOtpChange(combinedOtp);
    };

    const handleInput = (index, e) => {
        const value = e.target.value.replace([...otp][index], '');
        e.target.value = value;

        if (isNaN(value)) {
            return false;
        }

        if (index < inputRefs.length - 1 && value) {
            inputRefs[index + 1].current.focus();
            inputRefs[index + 1].current.setSelectionRange(0, 0);
        }

        updateOtp(index, value);
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace') {
            inputRefs[index].current.value = '';
            updateOtp(index, '');

            if (index > 0) {
                inputRefs[index - 1].current.focus();
                inputRefs[index - 1].current.setSelectionRange(0, 0);
                return false;
            }
        }

        if (e.key === 'Delete') {
            inputRefs[index].current.value = '';
            updateOtp(index, '');
        }

        if (e.key === 'ArrowLeft') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
                inputRefs[index - 1].current.setSelectionRange(0, 0);
                return false;
            }
        }

        if (e.key === 'ArrowRight') {
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1].current.focus();
                inputRefs[index + 1].current.setSelectionRange(0, 0);
                return false;
            }
        }
    };

    useEffect(() => {
        // Focus the first input when the component mounts
        inputRefs[0].current.focus();
        inputRefs[0].current.setSelectionRange(0, 0);
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {inputRefs.map((inputRef, index) => (
                <input
                    key={index}
                    type='text'
                    maxLength='2'
                    style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '18px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        textAlign: 'center',
                        margin: '5px',
                        color: 'white',
                        backgroundColor: 'black'
                        // transition: 'background-color 0.3s',
                    }}
                    ref={inputRef}
                    value={otp[index]}
                    onChange={(e) => handleInput(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                />
            ))}
        </div>
    );
};

export default OtpInput;

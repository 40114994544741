import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

import { ToastContainer } from 'react-toastify';

export default function LoginLayout() {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <main
                    style={{
                        background: 'linear-gradient(180deg, #2E1E1E, #120105)',
                        minHeight: '100vh',
                        overflow: 'hidden',
                        position: 'relative'
                    }}
                >
                    <Outlet />
                </main>
            </Suspense>

            <ToastContainer theme='dark' />
        </>
    );
}

import { toast } from 'react-toastify';
import { logout } from 'Services/Authentication';

const WrapperAPI = async (callback_function, { ignore_401, not_json } = {}) => {
    try {
        const res = await callback_function();

        if (res.status === 401 && !ignore_401) {
            toast.error('Your session has timed-out.\nRedirecting to login page...');
            setTimeout(() => {
                logout();
            }, 3000);
        }

        if (res.status >= 400) {
            toast.error(`Server Error Occurred - Status Code: ${res.status}`);
            throw new Error(`Status Code Error`);
        }

        try {
            if (not_json) {
                return await res.blob();
            } else {
                return await res.json();
            }
        } catch (err) {
            toast.error('Unexpected error occured!\nPlease try again later.');
            // throw new Error(`Network response was not ok - ${err}`);
        }
    } catch (err) {
        if (err.message !== 'Status Code Error') {
            toast.error('Network error occured!');
        }
        // throw new Error(`Network response was not ok - ${err}`);
    }
};

export default WrapperAPI;

import { createBrowserRouter, RouterProvider, Navigate, useLocation  } from 'react-router-dom';
import LoginLayout from '../Layouts/LoginLayout';
import LoginPage from '../Pages/LoginPage';
import InternalErrorPage from '../Pages/InternalErrorPage';

function SaveRedirectPath() {
    const location = useLocation();

    let originalPath = `${location.pathname}${location.search}`;
    let decodedPath = '';
    try {
        decodedPath = decodeURIComponent(originalPath);
    } catch (e) {
        decodedPath = '';
    }
    if (
        !/^[\w/-]*\?*([\w=&-]*)?$/.test(originalPath) ||
        !/^[\w/-]*\?*([\w=&-]*)?$/.test(decodedPath)
    ) {
        originalPath = location;
    }

    const prefixes = ['/unixi-management-portal', '/app', '/settings'];
    if (prefixes.some(prefix => originalPath.startsWith(prefix))) {
        localStorage.setItem('redirectPath', originalPath);
    }

    return <Navigate to="/" replace />;
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <LoginLayout />,
        errorElement: <InternalErrorPage />,
        children: [
            {
                path: '',
                element: <LoginPage formType="regular" />,
            },
            {
                path: 'login-mfa',
                element: <LoginPage formType="mfa" />,
            },
            {
                path: 'forgot-password',
                element: <LoginPage formType="forgot" />,
            },
            {
                path: 'reset-password',
                element: <LoginPage formType="reset" />,
            },
            {
                path: '*',
                element: <SaveRedirectPath />,
            },
        ],
    },
], { basename: '/login' });

export default function Router() {
    return <RouterProvider router={router} />;
}

import countries_json from 'Assets/datasets/countries.geojson';
import { useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';

export default function IntroGlobe(props) {
    const globeEl = useRef();
    const divEl = useRef();
    const [countries, setCountries] = useState({ features: [] });

    const globeMaterial = new THREE.MeshPhongMaterial({ color: '#3d1f38' });
    globeMaterial.bumpScale = 10;

    new THREE.TextureLoader().load(
        '//unpkg.com/three-globe/example/img/earth-water.png',
        (texture) => {
            globeMaterial.specularMap = texture;
            globeMaterial.specular = new THREE.Color('black');
            globeMaterial.shininess = 100;
            globeMaterial.color = new THREE.Color('#3d1f38');
        }
    );

    const loginAnimation = () => {
        globeEl.current.controls().autoRotateSpeed = 15;
        divEl.current.style.marginLeft = '100vw';
        divEl.current.style.transform = 'scale(5) translate(-25%)';
        divEl.current.style.zIndex = '10';
        divEl.current.style.filter = 'brightness(0%)';
    };

    useEffect(() => {
        fetch(countries_json)
            .then((res) => res.json())
            .then(setCountries);
        props.animateGlobe['animation'] = loginAnimation;

        // const directionalLight = globeEl.current.lights().find(obj3d => obj3d.type === 'DirectionalLight');
        // directionalLight && directionalLight.position.set(2, 2, 15); // change light position to see the specularMap's effect
    }, []);

    // globeEl.current.controls().autoRotate = true;

    return (
        <div ref={divEl} style={{ opacity: 0, transition: '3s', position: 'relative' }}>
            <Globe
                enablePointerInteraction={false}
                height={1200}
                width={1200}
                ref={globeEl}
                // globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                bumpImageUrl='//unpkg.com/three-globe/example/img/earth-topology.png'
                globeMaterial={globeMaterial}
                // globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                // bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                backgroundColor='#fff0'
                atmosphereColor='#adffff'
                atmosphereAltitude={0.1}
                hexPolygonsData={countries.features}
                hexPolygonResolution={3}
                hexPolygonMargin={0.3}
                hexPolygonUseDots={false}
                hexPolygonColor={() => '#854d6b'}
                onGlobeReady={() => {
                    // globeEl.current.controls().autoRotate = true;
                    // globeEl.current.controls().autoRotateSpeed = 1.0;
                    // globeEl.current.controls().enablePan = false;
                    // globeEl.current.controls().enableZoom = false;
                    // globeEl.current.controls().enableRotate = false;
                    // globeEl.current.controls().enableDamping = true;

                    // divEl.current.style.opacity = 1;
                    // // globeEl.current.controls().altitude = 7;
                    // // debugger;
                    // // globeEl.current.pointOfView({ altitude: 4 }, 5000)
                    if (globeEl.current && globeEl.current.controls) {
                        let controls = globeEl.current.controls();
                        controls.autoRotate = true;
                        controls.autoRotateSpeed = 1.0;
                        controls.enablePan = false;
                        controls.enableZoom = false;
                        controls.enableRotate = false;
                        controls.enableDamping = true;
                    }

                    if (divEl.current) {
                        divEl.current.style.opacity = 1;
                    }
                }}
            />

            {/* <div id="test" style={{position: 'absolute', borderRadius: '50%', width: '800px', height: '800px', boxShadow: '0px 0px 100px 100px #3d1f38', opacity: 0.5, top: '-400px', left: '-400px'}}>
            </div> */}
        </div>
    );
}

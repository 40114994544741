import React from 'react';

import Logo404 from 'Assets/404_icon.svg';
import Person404 from 'Assets/404_person.svg';

function InternalErrorPage() {
    // 500 ?

    const navigateBack = () => {
        window.location.href = '/unixi-management-portal/app/dashboard';
    };

    return (
        <main
            style={{
                background: 'linear-gradient(rgb(46, 30, 30), rgb(18, 1, 5)',
                minHeight: '100vh',
                overflow: 'hidden',
                position: 'relative',
                color: 'white'
            }}
        >
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '12.5%'
                }}
            >
                <img src={Logo404} style={{ height: '500px' }} />
                <img src={Person404} style={{ height: '175px' }} />
                <div style={{ textAlign: 'left', marginLeft: '-10px' }}>
                    <div style={{ fontSize: '90px', marginTop: '-130px' }}>Internal Error</div>
                    Oh no! Something went wrong
                    <br />
                    <b onClick={navigateBack} style={{ cursor: 'pointer' }}>
                        Click here to go back to the login page
                    </b>
                </div>
            </div>
        </main>
    );
}

export default InternalErrorPage;

import { logoutWithUnixi } from 'Services/AuthenticationApi';

const serverName = window.location.host;

const useAuthenticate = async (user_profile, tenant_profile) => {
    window.sessionStorage.setItem('user', JSON.stringify(user_profile));
    window.sessionStorage.setItem('tenant', JSON.stringify(tenant_profile));
};

const useIsAuthenticated = () => {
    if (window.sessionStorage.getItem('user') && window.sessionStorage.getItem('tenant')) {
        return true;
    }
};

// const getDisplayName = WrappedComponent => {
//     return WrappedComponent.displayName || WrappedComponent.name || 'Component';
// }

const getProfile = () => {
    return JSON.parse(window.sessionStorage.getItem('user'));
};

const getCompany = () => {
    return JSON.parse(window.sessionStorage.getItem('tenant'));
};

// const checkPermission = (permission) => {
//     let company = getCompany();
//     if (company && company['features'] && company['features'].includes(permission)) {
//         return true;
//     }
// };

const getPermissions = () => {
    let company_features = getCompany()['features'];
    let user_roles = getProfile();
    return company_features.concat([
        user_roles['role'],
        user_roles['super_admin'] ? 'super_admin' : null,
        user_roles['tenant_admin'] ? 'tenant_admin' : null
    ]);
};

const cleanSession = () => {
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('tenant');
};

const logout = (rememberedLogin = false) => {
    if (rememberedLogin) {
        window.postMessage({ type: `${serverName}-GET_USER_DATA` }, '*');
        let timeoutId;

        // Listen for the response from the content script
        const handleExtensionMessage = async (event) => {
            if (event.data && event.data.type === `${serverName}-USER_DATA_RESPONSE`) {
                const user_code = event.data.user_code;
                logoutWithUnixi(user_code).then((response) => {
                    if (response.status) {
                        window.postMessage({ type: `${serverName}-LOGOUT_WITH_UNIXI` }, '*');
                    }
                });
            } else if (event.data && event.data.type === `${serverName}-LOGOUT_WITH_UNIXI_DONE`) {
                clearTimeout(timeoutId);
                window.removeEventListener('message', handleExtensionMessage);
                cleanSession();
                window.location.href = '/login';
            }
        };

        window.addEventListener('message', handleExtensionMessage);

        timeoutId = setTimeout(() => {
            cleanSession();
            window.location.href = '/login';
        }, 5000);
    } else {
        cleanSession();
        window.location.href = '/login';
    }
    // window.location.href = '../';
};

export {
    cleanSession,
    getCompany,
    getPermissions,
    getProfile,
    logout,
    useAuthenticate,
    useIsAuthenticated
};
